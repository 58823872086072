
const UserStatusEnum = {

	new: 'new',
	open: 'open',
	block: 'block',
	delete: 'delete',

	getLabels: function() {

		return {
			new: 'new',
			open: 'active',
			block: 'block'
		}
	}

};

const UserRoleEnum = {

	user: 'user',
	partner: 'partner',
	manager: 'manager',
	admin: 'admin',

	getLabels: function() {

		return {
			user: 'user',
			partner: 'partner',
			manager: 'manager',
			admin: 'admin'
		}
	}
	
};

const UserGenderEnum = {

	male: 'male',
	female: 'female',

	getLabels: function() {

		return {
			male: 'male',
			female: 'female'
		}
	}

};

module.exports = {UserStatusEnum, UserRoleEnum, UserGenderEnum};