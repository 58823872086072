<template>
  <div class="user-page">
    <h1>{{ isEdit ? 'Edit user' : 'Add user' }}</h1>

    <form class="card card-body bg-light" @submit.prevent="isEdit ? doUserAdminEdit() : doUserAdminAdd()">

      <div class="col-12 col-lg-4 form-group">
        <label for="login" class="form-label">Login<span class="f-required">*</span></label>
        <input
            v-model="login"
            :class="{'is-invalid': errors?.login}"
            type="text"
            class="form-control"
            id="login" />
        <div class="invalid-feedback">{{ errors?.login }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="password" class="form-label">Password</label>
        <input
            v-model="password"
            :class="{'is-invalid': errors?.password}"
            type="password"
            class="form-control"
            autocomplete="false"
            placeholder="********"
            id="password" />
        <div class="invalid-feedback">{{ errors?.password }}</div>
        <small class="text-muted" v-if="isEdit">Leave the field empty if you don't want to change the password</small>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="name" class="form-label">Name</label>
        <input
            v-model="name"
            :class="{'is-invalid': errors?.name}"
            type="text"
            class="form-control"
            id="name" />
        <div class="invalid-feedback">{{ errors?.name }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="gender" class="form-label">Gender</label>
        <select
            v-model="gender"
            :class="{'is-invalid': errors?.gender}"
            class="form-control"
            id="gender">
          <option value="">...</option>
          <option v-for="(gender, key) in userGenderEnum.getLabels()" :key="key" :value="key">
            {{ gender }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.gender }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="email" class="form-label">Email<span class="f-required">*</span></label>
        <input
            v-model="email"
            :class="{'is-invalid': errors?.email}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="email" />
        <div class="invalid-feedback">{{ errors?.email }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="phone" class="form-label">Phone</label>
        <div class="input-group">
          <span class="input-group-text">+</span>
          <input
              v-model="phone"
              :class="{'is-invalid': errors?.phone}"
              type="text"
              class="form-control"
              id="phone" />
          <div class="invalid-feedback">{{ errors?.phone }}</div>
        </div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="passport" class="form-label">Passport</label>
        <input
            v-model="passport"
            :class="{'is-invalid': errors?.passport}"
            type="text"
            class="form-control"
            id="passport" />
        <div class="invalid-feedback">{{ errors?.passport }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="role" class="form-label">Role<span class="f-required">*</span></label>
        <select
            v-model="role"
            :class="{'is-invalid': errors?.role}"
            class="form-control"
            id="role">
          <option value="">...</option>
          <option v-for="(role, key) in userRoleEnum.getLabels()" :key="key" :value="key">
            {{ role }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.role }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="status" class="form-label">Status<span class="f-required">*</span></label>
        <select
            v-model="status"
            :class="{'is-invalid': errors?.status}"
            class="form-control"
            id="status">
          <option value="">...</option>
          <option v-for="(status, key) in userStatusEnum.getLabels()" :key="key" :value="key">
            {{ status }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.status }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <button class="btn btn-primary me-2" type="submit">Save</button>
        <router-link to="/user/admin" class="btn btn-outline-secondary">Cancel</router-link>
      </div>

    </form>

  </div>
</template>

<script>
import {UserStatusEnum, UserRoleEnum, UserGenderEnum} from "@/modules/user/enums/UserEnum";

export default {

  data: () => ({
    isEdit: false,

    login: null,
    password: null,
    name: null,
    passport: null,
    email: null,
    phone: null,
    gender: null,
    status: UserStatusEnum.new,
    role: UserRoleEnum.user,
    userGenderEnum: UserGenderEnum,
    userStatusEnum: UserStatusEnum,
    userRoleEnum: UserRoleEnum
  }),

  async mounted() {

    this.isEdit = this.$route.params?.id ? true : false;

    if (!await this.checkAdmin())
      return;

    this.setBreadcrumbs();

    if (this.isEdit)
      await this.doUserAdminEditInit();
  },

  methods: {

    async doUserAdminEditInit() {

      let apiHolder = await this.sendApiGet('api/user/admin/edit/init/' + this.$route.params?.id);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.userData) {

        this.login = apiHolder.data.userData?.login;
        this.email = apiHolder.data.userData?.email;
        this.phone = apiHolder.data.userData?.phone;
        this.name = apiHolder.data.userData?.name;
        this.gender = apiHolder.data.userData?.gender;
        this.passport = apiHolder.data.userData?.passport;
        this.status = apiHolder.data.userData?.status;
        this.role = apiHolder.data.userData?.role;
      }
    },

    async doUserAdminEdit() {

      let apiHolder = await this.sendApiPost('api/user/admin/edit/' + this.$route.params?.id, {
        login: this.login,
        password: this.password,
        name: this.name,
        passport: this.passport,
        gender: this.gender,
        email: this.email,
        phone: this.phone,
        status: this.status,
        role: this.role
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/user/admin');
    },

    async doUserAdminAdd() {

      let apiHolder = await this.sendApiPost('api/user/admin/add', {
        login: this.login,
        password: this.password,
        name: this.name,
        passport: this.passport,
        gender: this.gender,
        email: this.email,
        phone: this.phone,
        status: this.status,
        role: this.role
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/user/admin');
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        ['User management', '/user/admin'],
        this.isEdit ? 'Edit user' : 'Add user'
      ]);
    }
  }

}
</script>

<style scoped>

</style>